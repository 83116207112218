import moment from "moment";

let portfolioData = [
  {
    id: 1,
    name: "Alice",
    type: "Hold portfolio",
    properties: 25,
    units: 310,
    color: 1,
    aquired: "2020-01-20",
  },
  {
    id: 2,
    name: "Benedict",
    type: "Hold portfolio",
    properties: 1,
    units: 29,
    warnings: ["Vacant units"],
    color: 2,
    aquired: "2020-01-20",
  },
  {
    id: 3,
    name: "Challenge",
    type: "Hold portfolio",
    properties: 31,
    units: 998,
    color: 3,
    aquired: "2020-01-20",
  },
  {
    id: 4,
    name: "Kayden",
    type: "Hold portfolio",
    properties: 21,
    units: 910,
    color: 1,
    aquired: "2020-01-20",
  },
  {
    id: 5,
    name: "Natalie",
    type: "Hold portfolio",
    properties: 2,
    units: 103,
    color: 5,
    aquired: "2020-01-20",
  },
  {
    id: 6,
    name: "Tommy",
    type: "Hold portfolio",
    properties: 1,
    units: 16,
    warnings: ["Here's one more!"],
    color: 5,
    aquired: "2020-01-20",
  },
  {
    id: 7,
    name: "Allison",
    type: "Breakup portfolio",
    properties: 3,
    units: 49,
    color: 9,
    aquired: "2020-01-20",
  },
  {
    id: 8,
    name: "Emerick",
    type: "Breakup portfolio",
    properties: 27,
    units: 101,
    color: 7,
    aquired: "2020-01-20",
  },
  {
    id: 9,
    name: "Naomi",
    type: "Breakup portfolio",
    properties: 9,
    units: 651,
    color: 9,
    aquired: "2020-01-20",
  },
  {
    id: 10,
    name: "Theodore",
    type: "Breakup portfolio",
    properties: 125,
    units: 598,
    color: 9,
    aquired: "2020-01-20",
  },
  {
    id: 11,
    name: "Freedom",
    type: "Commercial portfolio",
    properties: 21,
    units: 25,
    color: 10,
    aquired: "2020-01-20",
  },
];

export const portfolios = portfolioData;

const propertyData = `1-3319	Borgmester Christiansens Gade 47B, 2450 København SV	Residential (95%)	 20,188 	 3,465,163 	 41,581,956 	 2,060
  1-3272	Mariendalsvej 57, 2000 Frederiksberg	Residential (100%)	 21,653 	 3,000,745 	 36,008,939 	 1,663
  5-7612	Rådmandsgade 61, 2200 København N	Residential (100%)	 7,359 	 1,290,278 	 15,483,336 	 2,104
  4-7761	Dannebrogsgade 34, 1660 København V	Residential (100%)	 16,203 	 2,414,247 	 28,970,964 	 1,788
  2-8737	Industriparken 32, 2750 Ballerup	Commercial (100%)	 12,568 	 2,056,963 	 24,683,552 	 1,964
  2-8744	Otto Busses Vej 10, 2450 København SV	Commercial (100%)	 9,523 	 1,320,523 	 15,846,272 	 1,664
  3-1861	Sverigesgade 8, 8000 Aarhus C	Residential (100%)	 11,418 	 2,203,674 	 26,444,088 	 2,316
  4-9071	Bådehavnsgade 38, 2450 København SV	Commercial (100%)	 21,420 	 3,009,510 	 36,114,120 	 1,686
  1-1023	Skolegyde 1, 8000 Aarhus C	Residential (91%)	 20,090 	 3,549,233 	 42,590,800 	 2,120
  2-1180	Nyvej 18, 1851 Frederiksberg C	Residential (100%)	 4,671 	 883,208 	 10,598,499 	 2,269
  1-9981	Torvet 19A, 4600 Køge	Residential (98%)	 26,346 	 4,718,130 	 56,617,554 	 2,149
  6-8817	Jernbanegade 18, 5000 Odense C	Residential (100%)	 18,855 	 2,543,854 	 30,526,245 	 1,619
  3-7615	Albani Torv 14B, 5000 Odense C	Residential (97%)	 8,690 	 1,248,463 	 14,981,560 	 1,724
  1-2281	Gammel Køge Landevej 39, 2500 Valby	Commercial (100%)	 9,119 	 1,760,727 	 21,128,723 	 2,317
  4-1655	Skt. Knuds Torv 9, 8000 Aarhus C	Residential (100%)	 6,033 	 1,173,419 	 14,081,022 	 2,334
  3-7163	Fredensgade 19, 8000 Aarhus C	Residential (91%)	 18,781 	 3,585,606 	 43,027,271 	 2,291
  5-9121	Værkmestergade 7, 8000 Aarhus C	Residential (100%)	 18,779 	 2,561,769 	 30,741,223 	 1,637`;

export const properties = propertyData
  .split("\n")
  .map((row) => row.split("\t").map((cell) => cell.trim()))
  .map((row, i) => ({
    id: i,
    unikId: row[0],
    address: row[1],
    propertyType: row[2],
    size: parseInt(row[3].replace(/,/g, "")),
    monthlyRent: parseInt(row[4].replace(/,/g, "")),
    yearlyRent: parseInt(row[5].replace(/,/g, "")),
    sqrmRent: parseInt(row[6].replace(/,/g, "")),
    financialUnit: Math.floor(Math.random() * 3 + 1),
    portfolioId: i % 2 != 0 ? Math.floor(Math.random() * 8 + 1) : undefined,
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 8 + 1))
      .utc()
      .format(),
  }));

const unitData = `1	1-3319-1-4	Viola Nørløvs Gade 18, 4 tv, 2450 København SV	84	Residential	3	14,574 kr.	174,888 kr.	2,082 kr.	12/1/2021
2	1-3319-2-4	Viola Nørløvs Gade 18, 1 th, 2450 København SV	60	Residential	2	10,355 kr.	124,260 kr.	2,071 kr.	6/1/2021
3	1-3319-3-4	Viola Nørløvs Gade 18, 3 th, 2450 København SV	60	Residential	2	10,250 kr.	123,000 kr.	2,050 kr.	8/1/2023
4	1-3319-4-5	Viola Nørløvs Gade 16, st , 2450 København SV	108	Residential	4	18,567 kr.	222,804 kr.	2,063 kr.		Vacant
5	1-3319-5-4	Viola Nørløvs Gade 20, st 1, 2450 København SV	59	Residential	2	10,069 kr.	120,832 kr.	2,048 kr.	4/1/2021
6	1-3319-6-8	Viola Nørløvs Gade 20, 1 2, 2450 København SV	66	Residential	2	11,220 kr.	134,640 kr.	2,040 kr.	7/1/2020
7	1-3319-7-2	Viola Nørløvs Gade 20, 2 2, 2450 København SV	66	Residential	2	11,297 kr.	135,564 kr.	2,054 kr.	12/1/2022
8	1-3319-8-2	Viola Nørløvs Gade 20, st 2, 2450 København SV	51	Residential	2	8,823 kr.	105,876 kr.	2,076 kr.	3/1/2021
9	1-3319-9-6	Viola Nørløvs Gade 20, 4 tv, 2450 København SV	56	Residential	2	9,683 kr.	116,200 kr.	2,075 kr.	12/1/2023
10	1-3319-10-4	Viola Nørløvs Gade 20, 1 4, 2450 København SV	50	Residential	2	8,550 kr.	102,600 kr.	2,052 kr.	12/1/2020
11	1-3319-11-4	Viola Nørløvs Gade 20, 2 4, 2450 København SV	50	Residential	2	8,492 kr.	101,900 kr.	2,038 kr.	5/1/2022
12	1-3319-12-8	Viola Nørløvs Gade 20, 3 4, 2450 København SV	50	Residential	2	8,663 kr.	103,950 kr.	2,079 kr.	2/1/2021
13	1-3319-13-4	Viola Nørløvs Gade 20, 4 mf, 2450 København SV	66	Residential	2	11,457 kr.	137,478 kr.	2,083 kr.	5/1/2023
14	1-3319-14-2	Viola Nørløvs Gade 20, 2 1, 2450 København SV	55	Residential	2	9,336 kr.	112,035 kr.	2,037 kr.	3/1/2021
15	1-3319-15-8	Viola Nørløvs Gade 20, 3 1, 2450 København SV	55	Residential	2	9,543 kr.	114,510 kr.	2,082 kr.	9/1/2022
16	1-3319-16-2	Viola Nørløvs Gade 14, 2 th, 2450 København SV	61	Residential	2	10,472 kr.	125,660 kr.	2,060 kr.	2/1/2022
17	1-3319-17-4	Viola Nørløvs Gade 12, 2 th, 2450 København SV	59	Residential	2	9,971 kr.	119,652 kr.	2,028 kr.	10/1/2022
18	1-3319-18-2	Viola Nørløvs Gade 12, 2 tv, 2450 København SV	121	Residential	5	21,024 kr.	252,285 kr.	2,085 kr.	3/1/2020
19	1-3319-19-8	Viola Nørløvs Gade 18, 1 tv, 2450 København SV	84	Residential	3	14,420 kr.	173,040 kr.	2,060 kr.	7/1/2023
20	1-3319-20-4	Viola Nørløvs Gade 18, 3 tv, 2450 København SV	84	Residential	3	14,154 kr.	169,848 kr.	2,022 kr.	4/1/2023
21	1-3319-21-4	Viola Nørløvs Gade 14, 3 th, 2450 København SV	61	Residential	2	10,477 kr.	125,721 kr.	2,061 kr.	11/1/2023
22	1-3319-22-8	Viola Nørløvs Gade 12, st tv, 2450 København SV	94	Residential	4	16,301 kr.	195,614 kr.	2,081 kr.	10/1/2022
23	1-3319-23-6	Viola Nørløvs Gade 14, 3 tv, 2450 København SV	72	Residential	3	12,216 kr.	146,592 kr.	2,036 kr.	10/1/2023
24	1-3319-24-8	Viola Nørløvs Gade 16, 3 th, 2450 København SV	60	Residential	2	10,260 kr.	123,120 kr.	2,052 kr.	7/1/2022
25	1-3319-25-6	Viola Nørløvs Gade 16, 4 th, 2450 København SV	60	Residential	2	10,305 kr.	123,660 kr.	2,061 kr.	5/1/2020
26	1-3319-26-2	Viola Nørløvs Gade 12, 1 tv, 2450 København SV	121	Residential	5	20,983 kr.	251,801 kr.	2,081 kr.	2/1/2021
27	1-3319-27-6	Borgmester Christiansens Gade 51B, 3 mf, 2450 København SV	41	Residential	1	6,943 kr.	83,312 kr.	2,032 kr.	11/1/2021
28	1-3319-28-6	Borgmester Christiansens Gade 51B, 4 mf, 2450 København SV	41	Residential	1	7,069 kr.	84,829 kr.	2,069 kr.	10/1/2023
29	1-3319-29-2	Borgmester Christiansens Gade 51A, 4 mf, 2450 København SV	69	Residential	2	11,989 kr.	143,865 kr.	2,085 kr.	6/1/2021
30	1-3319-30-2	Viola Nørløvs Gade 14, st , 2450 København SV	108	Residential	4	18,639 kr.	223,668 kr.	2,071 kr.	8/1/2020
31	1-3319-31-8	Borgmester Christiansens Gade 51B, 1 th, 2450 København SV	83	Residential	4	14,069 kr.	168,822 kr.	2,034 kr.	11/1/2020
32	1-3319-32-4	Borgmester Christiansens Gade 51B, 3 th, 2450 København SV	83	Residential	4	14,283 kr.	171,395 kr.	2,065 kr.	10/1/2021
33	1-3319-33-4	Borgmester Christiansens Gade 51B, 2 mf, 2450 København SV	41	Residential	1	7,035 kr.	84,419 kr.	2,059 kr.	3/1/2021
34	1-3319-34-8	Andrea Brochmanns Gade 16, 3 tv, 2450 København SV	84	Residential	3	14,343 kr.	172,116 kr.	2,049 kr.	11/1/2023
35	1-3319-35-8	Andrea Brochmanns Gade 18, 1 th, 2450 København SV	84	Residential	3	14,434 kr.	173,208 kr.	2,062 kr.	6/1/2023
36	1-3319-36-4	Anna Johansens Gade 1, st , 2450 København SV	79	Residential	3	13,377 kr.	160,528 kr.	2,032 kr.	7/1/2020
37	1-3319-37-2	Anna Johansens Gade 1, 1 mf, 2450 København SV	116	Residential	5	19,855 kr.	238,264 kr.	2,054 kr.	3/1/2021
38	1-3319-38-8	Anna Johansens Gade 1, 2 mf, 2450 København SV	115	Residential	5	19,387 kr.	232,645 kr.	2,023 kr.	3/1/2020
39	1-3319-39-2	Anna Johansens Gade 1, 1 th, 2450 København SV	89	Residential	3	15,442 kr.	185,298 kr.	2,082 kr.	9/1/2021
40	1-3319-40-8	Anna Johansens Gade 1, 4 th, 2450 København SV	95	Residential	3	16,364 kr.	196,365 kr.	2,067 kr.	8/1/2022
41	1-3319-41-8	Gerda Louw Larsens Plads 1, st th, 2450 København SV	110	Residential	5	18,783 kr.	225,390 kr.	2,049 kr.	3/1/2022
42	1-3319-42-8	Borgmester Christiansens Gade 51C, 1 th, 2450 København SV	61	Residential	2	10,390 kr.	124,684 kr.	2,044 kr.	6/1/2021
43	1-3319-43-4	Borgmester Christiansens Gade 51C, 1 tv, 2450 København SV	65	Residential	2	11,245 kr.	134,940 kr.	2,076 kr.	9/1/2023
44	1-3319-44-6	Borgmester Christiansens Gade 51C, 2 tv, 2450 København SV	65	Residential	2	11,023 kr.	132,275 kr.	2,035 kr.	4/1/2022
45	1-3319-45-2	Borgmester Christiansens Gade 51C, 3 tv, 2450 København SV	65	Residential	2	11,185 kr.	134,225 kr.	2,065 kr.	10/1/2021
46	1-3319-46-4	Gerda Louw Larsens Plads 1, 2 th, 2450 København SV	57	Residential	2	9,628 kr.	115,539 kr.	2,027 kr.	11/1/2021
47	1-3319-47-1	Borgmester Christiansens Gade 51A, 3 3, 2450 København SV	54	Residential	2	9,257 kr.	111,078 kr.	2,057 kr.		Vacant
48	1-3319-48-2	Borgmester Christiansens Gade 51A, 1 1, 2450 København SV	57	Residential	2	9,657 kr.	115,881 kr.	2,033 kr.	6/1/2020
49	1-3319-49-4	Borgmester Christiansens Gade 51A, 1 2, 2450 København SV	69	Residential	2	11,977 kr.	143,727 kr.	2,083 kr.	4/1/2021
50	1-3319-50-6	Borgmester Christiansens Gade 51B, 2 tv, 2450 København SV	56	Residential	2	9,730 kr.	116,760 kr.	2,085 kr.	11/1/2021
51	1-3319-51-2	Andrea Brochmanns Gade 14, 2 th, 2450 København SV	60	Residential	2	10,360 kr.	124,320 kr.	2,072 kr.	10/1/2023
52	1-3319-52-4	Gerda Louw Larsens Plads 1, 3 th, 2450 København SV	57	Residential	2	9,709 kr.	116,508 kr.	2,044 kr.	8/1/2020
53	1-3319-53-2	Borgmester Christiansens Gade 51, 2450 København SV	147	Commercial		31,348 kr.	376,173 kr.	2,559 kr.	2/1/2021
54	1-3319-54-2	Borgmester Christiansens Gade 51C, 2 th, 2450 København SV	61	Residential	2	10,360 kr.	124,318 kr.	2,038 kr.	6/1/2020
55	1-3319-55-8	Borgmester Christiansens Gade 51C, 3 th, 2450 København SV	61	Residential	2	10,279 kr.	123,342 kr.	2,022 kr.	2/1/2020
56	1-3319-56-8	Viola Nørløvs Gade 20, 1 3, 2450 København SV	51	Residential	2	8,598 kr.	103,173 kr.	2,023 kr.	3/1/2020
57	1-3319-57-2	Viola Nørløvs Gade 20, 3 3, 2450 København SV	51	Residential	2	8,653 kr.	103,836 kr.	2,036 kr.	12/1/2020
58	1-3319-58-4	Viola Nørløvs Gade 20, 4 th, 2450 København SV	59	Residential	2	10,133 kr.	121,599 kr.	2,061 kr.	2/1/2023
59	1-3319-59-4	Viola Nørløvs Gade 20, 3 2, 2450 København SV	66	Residential	2	11,385 kr.	136,620 kr.	2,070 kr.	5/1/2022
60	1-3319-60-6	Viola Nørløvs Gade 20, 2 3, 2450 København SV	51	Residential	2	8,759 kr.	105,111 kr.	2,061 kr.	10/1/2023
61	1-3319-61-6	Viola Nørløvs Gade 14, 1 tv, 2450 København SV	72	Residential	3	12,264 kr.	147,168 kr.	2,044 kr.	1/1/2022
62	1-3319-62-6	Viola Nørløvs Gade 16, 4 tv, 2450 København SV	89	Residential	3	15,189 kr.	182,272 kr.	2,048 kr.	3/1/2021
63	1-3319-63-4	Viola Nørløvs Gade 14, 1 th, 2450 København SV	61	Residential	2	10,517 kr.	126,209 kr.	2,069 kr.	3/1/2022
64	1-3319-64-6	Viola Nørløvs Gade 16, 3 tv, 2450 København SV	84	Residential	3	14,567 kr.	174,804 kr.	2,081 kr.	10/1/2021
65	1-3319-65-2	Viola Nørløvs Gade 16, 2 th, 2450 København SV	60	Residential	2	10,110 kr.	121,320 kr.	2,022 kr.	4/1/2023
66	1-3319-66-6	Viola Nørløvs Gade 16, 2 tv, 2450 København SV	84	Residential	3	14,581 kr.	174,972 kr.	2,083 kr.	8/1/2022
67	1-3319-67-6	Viola Nørløvs Gade 12, 3 th, 2450 København SV	59	Residential	2	10,187 kr.	122,248 kr.	2,072 kr.	7/1/2021
68	1-3319-68-8	Viola Nørløvs Gade 12, 3 tv, 2450 København SV	121	Residential	5	20,469 kr.	245,630 kr.	2,030 kr.	8/1/2022
69	1-3319-69-6	Viola Nørløvs Gade 12, 1 th, 2450 København SV	59	Residential	2	10,074 kr.	120,891 kr.	2,049 kr.	4/1/2022
70	1-3319-70-4	Andrea Brochmanns Gade 14, 1 th, 2450 København SV	60	Residential	2	10,110 kr.	121,320 kr.	2,022 kr.	5/1/2023
71	1-3319-71-6	Borgmester Christiansens Gade 51A, 2 2, 2450 København SV	69	Residential	2	11,851 kr.	142,209 kr.	2,061 kr.	12/1/2021
72	1-3319-72-4	Borgmester Christiansens Gade 51A, 3 2, 2450 København SV	69	Residential	2	11,885 kr.	142,623 kr.	2,067 kr.	2/1/2022
73	1-3319-73-6	Borgmester Christiansens Gade 51A, 3 1, 2450 København SV	57	Residential	2	9,709 kr.	116,508 kr.	2,044 kr.	7/1/2021
74	1-3319-74-4	Borgmester Christiansens Gade 51A, 2 1, 2450 København SV	57	Residential	2	9,719 kr.	116,622 kr.	2,046 kr.	7/1/2022
75	1-3319-75-2	Borgmester Christiansens Gade 51A, 3 4, 2450 København SV	57	Residential	2	9,809 kr.	117,705 kr.	2,065 kr.	2/1/2021
76	1-3319-76-8	Borgmester Christiansens Gade 51A, 4 tv, 2450 København SV	57	Residential	2	9,662 kr.	115,938 kr.	2,034 kr.	7/1/2023
77	1-3319-77-8	Borgmester Christiansens Gade 51A, 4 th, 2450 København SV	57	Residential	2	9,875 kr.	118,503 kr.	2,079 kr.	9/1/2021
78	1-3319-78-8	Borgmester Christiansens Gade 51A, 2 3, 2450 København SV	54	Residential	2	9,131 kr.	109,566 kr.	2,029 kr.	6/1/2023
79	1-3319-79-7	Borgmester Christiansens Gade 51A, 1 3, 2450 København SV	54	Residential	2	9,257 kr.	111,078 kr.	2,057 kr.		Vacant
80	1-3319-80-6	Borgmester Christiansens Gade 51A, 1 4, 2450 København SV	57	Residential	2	9,894 kr.	118,731 kr.	2,083 kr.	1/1/2023
81	1-3319-81-8	Anna Johansens Gade 1, 3 mf, 2450 København SV	115	Residential	5	19,809 kr.	237,705 kr.	2,067 kr.	12/1/2020
82	1-3319-82-6	Anna Johansens Gade 1, 2 th, 2450 København SV	90	Residential	3	15,300 kr.	183,600 kr.	2,040 kr.	12/1/2020
83	1-3319-83-2	Anna Johansens Gade 1, 3 th, 2450 København SV	90	Residential	3	15,353 kr.	184,230 kr.	2,047 kr.	5/1/2020
84	1-3319-84-6	Anna Johansens Gade 1, 3 tv, 2450 København SV	117	Residential	5	19,773 kr.	237,276 kr.	2,028 kr.	6/1/2020
85	1-3319-85-8	Anna Johansens Gade 1, 4 tv, 2450 København SV	119	Residential	5	20,666 kr.	247,996 kr.	2,084 kr.	12/1/2020
86	1-3319-86-2	Anna Johansens Gade 1, 1 tv, 2450 København SV	117	Residential	5	19,861 kr.	238,329 kr.	2,037 kr.	1/1/2020
87	1-3319-87-2	Andrea Brochmanns Gade 18, st tv, 2450 København SV	82	Residential	3	13,817 kr.	165,804 kr.	2,022 kr.	1/1/2023
88	1-3319-88-8	Borgmester Christiansens Gade 51B, 3 tv, 2450 København SV	56	Residential	2	9,478 kr.	113,736 kr.	2,031 kr.	5/1/2020
89	1-3319-89-2	Borgmester Christiansens Gade 51B, 2 th, 2450 København SV	83	Residential	4	14,048 kr.	168,573 kr.	2,031 kr.	8/1/2021
90	1-3319-90-6	Borgmester Christiansens Gade 51B, 4 tv, 2450 København SV	62	Residential	2	10,669 kr.	128,030 kr.	2,065 kr.	7/1/2021
91	1-3319-91-6	Borgmester Christiansens Gade 51B, 1 mf, 2450 København SV	41	Residential	1	6,898 kr.	82,779 kr.	2,019 kr.	7/1/2020
92	1-3319-92-4	Borgmester Christiansens Gade 51B, 1 tv, 2450 København SV	56	Residential	2	9,422 kr.	113,064 kr.	2,019 kr.	2/1/2023
93	1-3319-93-4	Viola Nørløvs Gade 18, 4 th, 2450 København SV	60	Residential	2	10,370 kr.	124,440 kr.	2,074 kr.	10/1/2023
94	1-3319-94-4	Viola Nørløvs Gade 20, 1 1, 2450 København SV	55	Residential	2	9,520 kr.	114,235 kr.	2,077 kr.	2/1/2020
95	1-3319-95-8	Viola Nørløvs Gade 20, st 3, 2450 København SV	50	Residential	2	8,671 kr.	104,050 kr.	2,081 kr.	4/1/2021
96	1-3319-96-6	Viola Nørløvs Gade 12, st th, 2450 København SV	118	Residential	4	19,893 kr.	238,714 kr.	2,023 kr.	12/1/2020
97	1-3319-97-4	Viola Nørløvs Gade 20, st 4, 2450 København SV	67	Residential	2	11,530 kr.	138,355 kr.	2,065 kr.	5/1/2021
98	1-3319-98-6	Viola Nørløvs Gade 16, 1 th, 2450 København SV	60	Residential	2	10,290 kr.	123,480 kr.	2,058 kr.	6/1/2021
99	1-3319-99-6	Viola Nørløvs Gade 14, 2 tv, 2450 København SV	72	Residential	3	12,408 kr.	148,896 kr.	2,068 kr.	6/1/2023
100	1-3319-100-8	Borgmester Christiansens Gade 51B, st tv, 2450 København SV	109	Residential	5	18,694 kr.	224,322 kr.	2,058 kr.	4/1/2020
101	1-3319-101-6	Viola Nørløvs Gade 16, 1 tv, 2450 København SV	84	Residential	3	14,273 kr.	171,276 kr.	2,039 kr.	10/1/2020
102	1-3319-102-2	Anna Johansens Gade 1, 2 tv, 2450 København SV	117	Residential	5	20,319 kr.	243,828 kr.	2,084 kr.	5/1/2021
103	1-3319-103-6	Borgmester Christiansens Gade 51C, st , 2450 København SV	58	Residential	2	9,807 kr.	117,682 kr.	2,029 kr.	2/1/2023
104	1-3319-104-6	Borgmester Christiansens Gade 51A, 2 4, 2450 København SV	57	Residential	2	9,861 kr.	118,332 kr.	2,076 kr.	7/1/2020
105	1-3319-105-2	Borgmester Christiansens Gade 51B, 4 th, 2450 København SV	83	Residential	4	14,421 kr.	173,055 kr.	2,085 kr.	7/1/2023
106	1-3319-106-2	Borgmester Christiansens Gade 51B, st th, 2450 København SV	71	Residential	2	12,153 kr.	145,834 kr.	2,054 kr.	6/1/2020
107	1-3319-107-2	Andrea Brochmanns Gade 16, st tv, 2450 København SV	109	Residential	4	18,403 kr.	220,834 kr.	2,026 kr.	6/1/2023
108	1-3319-108-6	Andrea Brochmanns Gade 18, 3 tv, 2450 København SV	60	Residential	2	10,310 kr.	123,720 kr.	2,062 kr.	3/1/2021
109	1-3319-109-8	Andrea Brochmanns Gade 18, 2 th, 2450 København SV	84	Residential	3	14,245 kr.	170,940 kr.	2,035 kr.	4/1/2021
110	1-3319-110-7	Andrea Brochmanns Gade 18, 3 th, 2450 København SV	84	Residential	3	14,189 kr.	170,268 kr.	2,027 kr.		Vacant
111	1-3319-111-2	Andrea Brochmanns Gade 18, 4 , 2450 København SV	86	Residential	3	14,541 kr.	174,494 kr.	2,029 kr.	7/1/2020
112	1-3319-112-6	Andrea Brochmanns Gade 18, 1 tv, 2450 København SV	60	Residential	2	10,135 kr.	121,620 kr.	2,027 kr.	4/1/2022
113	1-3319-113-6	Andrea Brochmanns Gade 18, 2 tv, 2450 København SV	60	Residential	2	10,310 kr.	123,720 kr.	2,062 kr.	8/1/2022
114	1-3319-114-8	Andrea Brochmanns Gade 16, 2 th, 2450 København SV	60	Residential	2	10,335 kr.	124,020 kr.	2,067 kr.	7/1/2023
115	1-3319-115-8	Andrea Brochmanns Gade 16, 1 th, 2450 København SV	60	Residential	2	10,145 kr.	121,740 kr.	2,029 kr.	3/1/2021
116	1-3319-116-8	Andrea Brochmanns Gade 16, 3 th, 2450 København SV	60	Residential	2	10,325 kr.	123,900 kr.	2,065 kr.	4/1/2021
117	1-3319-117-2	Andrea Brochmanns Gade 16, st th, 2450 København SV	82	Residential	3	13,824 kr.	165,886 kr.	2,023 kr.	11/1/2022
118	1-3319-118-4	Andrea Brochmanns Gade 16, 2 tv, 2450 København SV	84	Residential	3	14,525 kr.	174,300 kr.	2,075 kr.	4/1/2022
119	1-3319-119-6	Andrea Brochmanns Gade 16, 1 tv, 2450 København SV	84	Residential	3	14,252 kr.	171,024 kr.	2,036 kr.	3/1/2023
120	1-3319-120-6	Andrea Brochmanns Gade 16, 4 tv, 2450 København SV	84	Residential	3	14,364 kr.	172,368 kr.	2,052 kr.	8/1/2022
121	1-3319-121-6	Andrea Brochmanns Gade 16, 4 th, 2450 København SV	60	Residential	2	10,115 kr.	121,380 kr.	2,023 kr.	8/1/2021
122	1-3319-122-8	Andrea Brochmanns Gade 14, 2 tv, 2450 København SV	119	Residential	5	20,587 kr.	247,044 kr.	2,076 kr.	9/1/2022
123	1-3319-123-2	Andrea Brochmanns Gade 14, 3 th, 2450 København SV	60	Residential	2	10,365 kr.	124,380 kr.	2,073 kr.	11/1/2022
124	1-3319-124-2	Andrea Brochmanns Gade 14, 4 , 2450 København SV	77	Residential	2	13,000 kr.	156,002 kr.	2,026 kr.	3/1/2023
125	1-3319-125-6	Andrea Brochmanns Gade 14, st , 2450 København SV	97	Residential	4	16,716 kr.	200,596 kr.	2,068 kr.	10/1/2022
126	1-3319-126-6	Andrea Brochmanns Gade 14, 1 tv, 2450 København SV	119	Residential	5	20,052 kr.	240,618 kr.	2,022 kr.	7/1/2023
127	1-3319-127-2	Andrea Brochmanns Gade 14, 3 tv, 2450 København SV	119	Residential	5	20,448 kr.	245,378 kr.	2,062 kr.	9/1/2023
128	1-3319-128-8	Gerda Louw Larsens Plads 1, 1 th, 2450 København SV	57	Residential	2	9,590 kr.	115,083 kr.	2,019 kr.	5/1/2022
129	1-3319-129-4	Gerda Louw Larsens Plads 1, 3 tv, 2450 København SV	84	Residential	4	14,525 kr.	174,300 kr.	2,075 kr.	12/1/2021
130	1-3319-130-6	Gerda Louw Larsens Plads 1, st tv, 2450 København SV	64	Residential	2	10,960 kr.	131,520 kr.	2,055 kr.	8/1/2020
131	1-3319-131-4	Gerda Louw Larsens Plads 1, 2 tv, 2450 København SV	84	Residential	4	14,406 kr.	172,872 kr.	2,058 kr.	7/1/2022
132	1-3319-132-8	Gerda Louw Larsens Plads 1, 1 tv, 2450 København SV	84	Residential	4	14,462 kr.	173,544 kr.	2,066 kr.	4/1/2022
133	1-3319-133-8	Gerda Louw Larsens Plads 1, 1 mf, 2450 København SV	42	Residential	1	7,207 kr.	86,478 kr.	2,059 kr.	1/1/2021
134	1-3319-134-6	Gerda Louw Larsens Plads 1, 2 mf, 2450 København SV	42	Residential	1	7,263 kr.	87,150 kr.	2,075 kr.	5/1/2022
135	1-3319-135-2	Gerda Louw Larsens Plads 1, 3 mf, 2450 København SV	42	Residential	1	7,172 kr.	86,058 kr.	2,049 kr.	7/1/2020
136	1-3319-136-6	Viola Nørløvs Gade 18, 2 tv, 2450 København SV	84	Residential	3	14,203 kr.	170,436 kr.	2,029 kr.	3/1/2022
137	1-3319-137-8	Viola Nørløvs Gade 18, 2 th, 2450 København SV	60	Residential	2	10,205 kr.	122,460 kr.	2,041 kr.	11/1/2023
138	1-3319-138-2	Fanny Jensens Plads 3, 4 th, 2450 København SV	59	Residential	2	9,986 kr.	119,829 kr.	2,031 kr.	11/1/2020
139	1-3319-139-8	Fanny Jensens Plads 3, 1 tv, 2450 København SV	70	Residential	3	11,853 kr.	142,240 kr.	2,032 kr.	2/1/2022
140	1-3319-140-8	Fanny Jensens Plads 3, 3 tv, 2450 København SV	70	Residential	3	11,778 kr.	141,330 kr.	2,019 kr.	10/1/2023
141	1-3319-141-2	Fanny Jensens Plads 3, 1 th, 2450 København SV	59	Residential	2	9,961 kr.	119,534 kr.	2,026 kr.	7/1/2022
142	1-3319-142-2	Fanny Jensens Plads 3, 2 th, 2450 København SV	59	Residential	2	9,946 kr.	119,357 kr.	2,023 kr.	9/1/2020
143	1-3319-143-4	Fanny Jensens Plads 3, 3 th, 2450 København SV	59	Residential	2	10,138 kr.	121,658 kr.	2,062 kr.	7/1/2022
144	1-3319-144-6	Fanny Jensens Plads 5, 1 tv, 2450 København SV	69	Residential	3	11,897 kr.	142,761 kr.	2,069 kr.	2/1/2023
145	1-3319-145-2	Fanny Jensens Plads 5, 3 tv, 2450 København SV	69	Residential	3	11,673 kr.	140,070 kr.	2,030 kr.	2/1/2021
146	1-3319-146-8	Fanny Jensens Plads 1, 1 mf, 2450 København SV	116	Residential	5	19,923 kr.	239,076 kr.	2,061 kr.	5/1/2023
147	1-3319-147-4	Fanny Jensens Plads 1, 4 mf, 2450 København SV	116	Residential	5	19,730 kr.	236,756 kr.	2,041 kr.	6/1/2023
148	1-3319-148-8	Fanny Jensens Plads 1, 1 tv, 2450 København SV	89	Residential	3	15,293 kr.	183,518 kr.	2,062 kr.	11/1/2023
149	1-3319-149-8	Fanny Jensens Plads 1, 2 tv, 2450 København SV	89	Residential	3	15,338 kr.	184,052 kr.	2,068 kr.	2/1/2022
150	1-3319-150-3	Fanny Jensens Plads 1, st tv, 2450 København SV	108	Residential	4	18,180 kr.	218,160 kr.	2,020 kr.		Vacant
151	1-3319-151-2	Fanny Jensens Plads 3, 2 tv, 2450 København SV	70	Residential	3	12,052 kr.	144,620 kr.	2,066 kr.	10/1/2021
152	1-3319-152-4	Fanny Jensens Plads 7, 3 th, 2450 København SV	104	Residential	5	18,053 kr.	216,632 kr.	2,083 kr.	5/1/2020
153	1-3319-153-4	Fanny Jensens Plads 7, 4 th, 2450 København SV	45	Residential	1	7,699 kr.	92,385 kr.	2,053 kr.	10/1/2022
154	1-3319-154-8	Fanny Jensens Plads 7, 5 th, 2450 København SV	45	Residential	1	7,744 kr.	92,925 kr.	2,065 kr.	5/1/2020
155	1-3319-155-4	Fanny Jensens Plads 7, st th, 2450 København SV	96	Residential	4	16,288 kr.	195,456 kr.	2,036 kr.	11/1/2022
156	1-3319-156-8	Fanny Jensens Plads 1, 1 th, 2450 København SV	89	Residential	3	15,026 kr.	180,314 kr.	2,026 kr.	3/1/2022
157	1-3319-157-4	Fanny Jensens Plads 7, 5 tv, 2450 København SV	70	Residential	3	12,040 kr.	144,480 kr.	2,064 kr.	8/1/2022
158	1-3319-158-4	Fanny Jensens Plads 7, 1 mf, 2450 København SV	43	Residential	1	7,274 kr.	87,290 kr.	2,030 kr.	10/1/2022
159	1-3319-159-2	Fanny Jensens Plads 7, 2 mf, 2450 København SV	43	Residential	1	7,357 kr.	88,279 kr.	2,053 kr.	6/1/2020
160	1-3319-160-4	Fanny Jensens Plads 7, 1 th, 2450 København SV	104	Residential	5	17,983 kr.	215,800 kr.	2,075 kr.	6/1/2022
161	1-3319-161-4	Fanny Jensens Plads 7, 2 th, 2450 København SV	104	Residential	5	18,053 kr.	216,632 kr.	2,083 kr.	3/1/2021
162	1-3319-162-4	Fanny Jensens Plads 5, 2 th, 2450 København SV	58	Residential	2	9,821 kr.	117,856 kr.	2,032 kr.	12/1/2020
163	1-3319-163-4	Fanny Jensens Plads 5, st , 2450 København SV	71	Residential	3	12,218 kr.	146,615 kr.	2,065 kr.	6/1/2020
164	1-3319-164-4	Fanny Jensens Plads 7, 2 tv, 2450 København SV	70	Residential	3	12,122 kr.	145,460 kr.	2,078 kr.	2/1/2020
165	1-3319-165-6	Fanny Jensens Plads 7, 3 tv, 2450 København SV	70	Residential	3	12,063 kr.	144,760 kr.	2,068 kr.	11/1/2023
166	1-3319-166-2	Andrea Brochmanns Gade 10, 3 tv, 2450 København SV	104	Residential	4	17,897 kr.	214,760 kr.	2,065 kr.	11/1/2023
167	1-3319-167-4	Gerda Louw Larsens Plads 2, 2 tv, 2450 København SV	86	Residential	3	14,484 kr.	173,806 kr.	2,021 kr.	12/1/2023
168	1-3319-168-6	Gerda Louw Larsens Plads 2, 3 tv, 2450 København SV	86	Residential	3	14,520 kr.	174,236 kr.	2,026 kr.	2/1/2023
169	1-3319-169-2	Gerda Louw Larsens Plads 2, 1 th, 2450 København SV	62	Residential	2	10,457 kr.	125,488 kr.	2,024 kr.	8/1/2022
170	1-3319-170-2	Gerda Louw Larsens Plads 2, 3 th, 2450 København SV	62	Residential	2	10,721 kr.	128,650 kr.	2,075 kr.	7/1/2021
171	1-3319-171-8	Gerda Louw Larsens Plads 4, st 3, 2450 København SV	55	Residential	2	9,364 kr.	112,365 kr.	2,043 kr.	7/1/2021
172	1-3319-172-6	Gerda Louw Larsens Plads 4, 1 1, 2450 København SV	68	Residential	3	11,481 kr.	137,768 kr.	2,026 kr.	1/1/2023
173	1-3319-173-8	Andrea Brochmanns Gade 10, 1 th, 2450 København SV	93	Residential	4	15,880 kr.	190,557 kr.	2,049 kr.	11/1/2022
174	1-3319-174-4	Andrea Brochmanns Gade 10, 3 th, 2450 København SV	93	Residential	4	16,120 kr.	193,440 kr.	2,080 kr.	2/1/2022
175	1-3319-175-2	Borgmester Christiansens Gade 47D, st tv, 2450 København SV	69	Residential	2	11,621 kr.	139,449 kr.	2,021 kr.	7/1/2021
176	1-3319-176-8	Gerda Louw Larsens Plads 4, 2 4, 2450 København SV	55	Residential	2	9,268 kr.	111,210 kr.	2,022 kr.	3/1/2020
177	1-3319-177-4	Gerda Louw Larsens Plads 4, st 4, 2450 København SV	67	Residential	2	11,418 kr.	137,015 kr.	2,045 kr.	2/1/2020
178	1-3319-178-8	Gerda Louw Larsens Plads 4, 2 3, 2450 København SV	52	Residential	2	8,857 kr.	106,288 kr.	2,044 kr.	10/1/2023
179	1-3319-179-6	Gerda Louw Larsens Plads 4, 3 3, 2450 København SV	52	Residential	2	8,784 kr.	105,404 kr.	2,027 kr.	8/1/2020
180	1-3319-180-4	Andrea Brochmanns Gade 12, 2 th, 2450 København SV	122	Residential	5	21,147 kr.	253,760 kr.	2,080 kr.	4/1/2023
181	1-3319-181-6	Andrea Brochmanns Gade 12, 3 th, 2450 København SV	122	Residential	5	21,025 kr.	252,296 kr.	2,068 kr.	5/1/2021
182	1-3319-182-4	Borgmester Christiansens Gade 47D, 1 th, 2450 København SV	86	Residential	4	14,735 kr.	176,816 kr.	2,056 kr.	9/1/2023
183	1-3319-183-4	Borgmester Christiansens Gade 47D, 2 th, 2450 København SV	86	Residential	4	14,563 kr.	174,752 kr.	2,032 kr.	7/1/2022
184	1-3319-184-2	Borgmester Christiansens Gade 47D, 1 tv, 2450 København SV	64	Residential	2	10,896 kr.	130,752 kr.	2,043 kr.	8/1/2021
185	1-3319-185-8	Borgmester Christiansens Gade 47C, 4 th, 2450 København SV	60	Residential	2	10,205 kr.	122,460 kr.	2,041 kr.	4/1/2023
186	1-3319-186-4	Borgmester Christiansens Gade 47C, st , 2450 København SV	77	Residential	3	12,987 kr.	155,848 kr.	2,024 kr.	2/1/2023
187	1-3319-187-4	Borgmester Christiansens Gade 47C, 2 tv, 2450 København SV	84	Residential	3	14,371 kr.	172,452 kr.	2,053 kr.	3/1/2021
188	1-3319-188-2	Borgmester Christiansens Gade 47C, 3 tv, 2450 København SV	84	Residential	3	14,266 kr.	171,192 kr.	2,038 kr.	9/1/2020
189	1-3319-189-8	Andrea Brochmanns Gade 12, 2 tv, 2450 København SV	102	Residential	4	17,340 kr.	208,080 kr.	2,040 kr.	11/1/2022
190	1-3319-190-6	Borgmester Christiansens Gade 47A, 1 1, 2450 København SV	56	Residential	2	9,613 kr.	115,360 kr.	2,060 kr.	5/1/2020
191	1-3319-191-8	Borgmester Christiansens Gade 47A, 3 1, 2450 København SV	56	Residential	2	9,553 kr.	114,632 kr.	2,047 kr.	2/1/2020
192	1-3319-192-2	Borgmester Christiansens Gade 47B, 1 tv, 2450 København SV	57	Residential	2	9,685 kr.	116,223 kr.	2,039 kr.	11/1/2023
193	1-3319-193-2	Borgmester Christiansens Gade 47A, 2 4, 2450 København SV	57	Residential	2	9,647 kr.	115,767 kr.	2,031 kr.	5/1/2022
194	1-3319-194-6	Gerda Louw Larsens Plads 4, 1 4, 2450 København SV	55	Residential	2	9,359 kr.	112,310 kr.	2,042 kr.	4/1/2020
195	1-3319-195-6	Gerda Louw Larsens Plads 4, 3 4, 2450 København SV	55	Residential	2	9,506 kr.	114,070 kr.	2,074 kr.	8/1/2020
196	1-3319-196-2	Andrea Brochmanns Gade 12, 1 tv, 2450 København SV	102	Residential	4	17,391 kr.	208,692 kr.	2,046 kr.	2/1/2023
197	1-3319-197-6	Andrea Brochmanns Gade 12, 3 tv, 2450 København SV	102	Residential	4	17,170 kr.	206,040 kr.	2,020 kr.	12/1/2020
198	1-3319-198-6	Borgmester Christiansens Gade 47B, 3 tv, 2450 København SV	57	Residential	2	9,780 kr.	117,363 kr.	2,059 kr.	1/1/2023
199	1-3319-199-8	Borgmester Christiansens Gade 47B, 4 tv, 2450 København SV	57	Residential	2	9,628 kr.	115,539 kr.	2,027 kr.	9/1/2021
200	1-3319-200-6	Borgmester Christiansens Gade 47C, 1 tv, 2450 København SV	84	Residential	3	14,497 kr.	173,964 kr.	2,071 kr.	12/1/2022
201	1-3319-201-6	Borgmester Christiansens Gade 47C, 4 tv, 2450 København SV	89	Residential	3	15,115 kr.	181,382 kr.	2,038 kr.	6/1/2023
202	1-3319-202-8	Borgmester Christiansens Gade 47A, 5 mf, 2450 København SV	54	Residential	2	9,212 kr.	110,538 kr.	2,047 kr.	4/1/2020
203	1-3319-203-4	Borgmester Christiansens Gade 47A, 3 4, 2450 København SV	57	Residential	2	9,866 kr.	118,389 kr.	2,077 kr.	1/1/2022
204	1-3319-204-8	Andrea Brochmanns Gade 12, st tv, 2450 København SV	105	Residential	4	17,955 kr.	215,460 kr.	2,052 kr.	3/1/2020
205	1-3319-205-2	Andrea Brochmanns Gade 12, 1 th, 2450 København SV	122	Residential	5	20,801 kr.	249,612 kr.	2,046 kr.	5/1/2020
206	1-3319-206-4	Andrea Brochmanns Gade 12, st th, 2450 København SV	100	Residential	3	17,075 kr.	204,900 kr.	2,049 kr.	10/1/2022
207	1-3319-207-4	Gerda Louw Larsens Plads 2, 2 th, 2450 København SV	62	Residential	2	10,519 kr.	126,232 kr.	2,036 kr.	4/1/2022
208	1-3319-208-8	Gerda Louw Larsens Plads 2, 4 , 2450 København SV	79	Residential	2	13,470 kr.	161,634 kr.	2,046 kr.	12/1/2021
209	1-3319-209-8	Andrea Brochmanns Gade 10, 1 tv, 2450 København SV	104	Residential	4	17,897 kr.	214,760 kr.	2,065 kr.	11/1/2023
210	1-3319-210-2	Andrea Brochmanns Gade 10, 2 tv, 2450 København SV	104	Residential	4	17,905 kr.	214,864 kr.	2,066 kr.	6/1/2021
211	1-3319-211-6	Andrea Brochmanns Gade 10, 2 th, 2450 København SV	93	Residential	4	16,136 kr.	193,626 kr.	2,082 kr.	6/1/2021
212	1-3319-212-8	Andrea Brochmanns Gade 10, 4 , 2450 København SV	106	Residential	4	17,905 kr.	214,862 kr.	2,027 kr.	4/1/2023
213	1-3319-213-6	Gerda Louw Larsens Plads 4, 4 tv, 2450 København SV	55	Residential	2	9,254 kr.	111,045 kr.	2,019 kr.	3/1/2023
214	1-3319-214-8	Gerda Louw Larsens Plads 4, 3 2, 2450 København SV	68	Residential	3	11,690 kr.	140,284 kr.	2,063 kr.	8/1/2022
215	1-3319-215-4	Gerda Louw Larsens Plads 4, 1 3, 2450 København SV	55	Residential	2	9,410 kr.	112,915 kr.	2,053 kr.	9/1/2021
216	1-3319-216-4	Gerda Louw Larsens Plads 4, 3 1, 2450 København SV	55	Residential	2	9,254 kr.	111,045 kr.	2,019 kr.	1/1/2023
217	1-3319-217-4	Gerda Louw Larsens Plads 4, 2 1, 2450 København SV	55	Residential	2	9,364 kr.	112,365 kr.	2,043 kr.	1/1/2020
218	1-3319-218-2	Gerda Louw Larsens Plads 4, 4 mf, 2450 København SV	68	Residential	3	11,815 kr.	141,780 kr.	2,085 kr.	6/1/2020
219	1-3319-219-6	Gerda Louw Larsens Plads 4, 4 th, 2450 København SV	60	Residential	2	10,400 kr.	124,800 kr.	2,080 kr.	8/1/2023
220	1-3319-220-8	Fanny Jensens Plads 7, 4 tv, 2450 København SV	70	Residential	3	11,848 kr.	142,170 kr.	2,031 kr.	4/1/2023
221	1-3319-221-2	Gerda Louw Larsens Plads 4, 1 2, 2450 København SV	52	Residential	2	8,758 kr.	105,092 kr.	2,021 kr.	12/1/2022
222	1-3319-222-4	Fanny Jensens Plads 7, 3 mf, 2450 København SV	43	Residential	1	7,314 kr.	87,763 kr.	2,041 kr.	7/1/2020
223	1-3319-223-2	Fanny Jensens Plads 7, st tv, 2450 København SV	84	Residential	3	14,147 kr.	169,764 kr.	2,021 kr.	1/1/2023
224	1-3319-224-4	Borgmester Christiansens Gade 47B, 1 th, 2450 København SV	84	Residential	4	14,259 kr.	171,108 kr.	2,037 kr.	12/1/2020
225	1-3319-225-4	Borgmester Christiansens Gade 47B, 2 mf, 2450 København SV	42	Residential	1	7,144 kr.	85,722 kr.	2,041 kr.	10/1/2021
226	1-3319-226-2	Borgmester Christiansens Gade 47B, 4 th, 2450 København SV	63	Residential	2	10,668 kr.	128,016 kr.	2,032 kr.	9/1/2023
227	1-3319-227-8	Borgmester Christiansens Gade 47B, 2 th, 2450 København SV	84	Residential	4	14,301 kr.	171,612 kr.	2,043 kr.	4/1/2020
228	1-3319-228-2	Borgmester Christiansens Gade 47B, 2 tv, 2450 København SV	57	Residential	2	9,847 kr.	118,161 kr.	2,073 kr.	2/1/2021
229	1-3319-229-6	Borgmester Christiansens Gade 47B, 3 mf, 2450 København SV	42	Residential	1	7,217 kr.	86,604 kr.	2,062 kr.	7/1/2022
230	1-3319-230-2	Borgmester Christiansens Gade 47B, 3 th, 2450 København SV	84	Residential	4	14,455 kr.	173,460 kr.	2,065 kr.	8/1/2021
231	1-3319-231-8	Borgmester Christiansens Gade 47D, 3 th, 2450 København SV	86	Residential	4	14,849 kr.	178,192 kr.	2,072 kr.	3/1/2022
232	1-3319-232-4	Borgmester Christiansens Gade 47D, 2 tv, 2450 København SV	64	Residential	2	11,083 kr.	132,992 kr.	2,078 kr.	7/1/2023
233	1-3319-233-2	Borgmester Christiansens Gade 47D, 3 tv, 2450 København SV	64	Residential	2	10,917 kr.	131,008 kr.	2,047 kr.	6/1/2021
234	1-3319-234-8	Borgmester Christiansens Gade 47D, st th, 2450 København SV	56	Residential	2	9,445 kr.	113,344 kr.	2,024 kr.	6/1/2022
235	1-3319-235-8	Fanny Jensens Plads 5, 3 th, 2450 København SV	58	Residential	2	10,010 kr.	120,118 kr.	2,071 kr.	9/1/2020
236	1-3319-236-2	Fanny Jensens Plads 5, 1 th, 2450 København SV	58	Residential	2	9,870 kr.	118,436 kr.	2,042 kr.	1/1/2022
237	1-3319-237-4	Borgmester Christiansens Gade 47A, 4 mf, 2450 København SV	54	Residential	2	9,261 kr.	111,132 kr.	2,058 kr.	4/1/2023
238	1-3319-238-4	Borgmester Christiansens Gade 47A, 4 th, 2450 København SV	57	Residential	2	9,690 kr.	116,280 kr.	2,040 kr.	2/1/2022
239	1-3319-239-4	Borgmester Christiansens Gade 47A, 3 3, 2450 København SV	54	Residential	2	9,297 kr.	111,564 kr.	2,066 kr.	8/1/2022
240	1-3319-240-8	Borgmester Christiansens Gade 47A, 5 tv, 2450 København SV	68	Residential	2	11,554 kr.	138,652 kr.	2,039 kr.	11/1/2021
241	1-3319-241-4	Borgmester Christiansens Gade 47A, 4 tv, 2450 København SV	68	Residential	2	11,537 kr.	138,448 kr.	2,036 kr.	2/1/2023
242	1-3319-242-4	Borgmester Christiansens Gade 47A, 5 th, 2450 København SV	57	Residential	2	9,647 kr.	115,767 kr.	2,031 kr.	4/1/2021
243	1-3319-243-6	Fanny Jensens Plads 1, 4 th, 2450 København SV	89	Residential	3	14,974 kr.	179,691 kr.	2,019 kr.	7/1/2023
244	1-3319-244-6	Borgmester Christiansens Gade 47A, 2 1, 2450 København SV	56	Residential	2	9,436 kr.	113,232 kr.	2,022 kr.	2/1/2023
245	1-3319-245-6	Borgmester Christiansens Gade 47A, 2 3, 2450 København SV	54	Residential	2	9,225 kr.	110,700 kr.	2,050 kr.	8/1/2022
246	1-3319-246-6	Borgmester Christiansens Gade 47A, 1 4, 2450 København SV	57	Residential	2	9,685 kr.	116,223 kr.	2,039 kr.	7/1/2021
247	1-3319-247-8	Borgmester Christiansens Gade 47A, 2 2, 2450 København SV	66	Residential	2	11,105 kr.	133,254 kr.	2,019 kr.	12/1/2021
248	1-3319-248-2	Borgmester Christiansens Gade 47A, 1 3, 2450 København SV	54	Residential	2	9,270 kr.	111,240 kr.	2,060 kr.	1/1/2020
249	1-3319-249-6	Fanny Jensens Plads 3, 4 tv, 2450 København SV	75	Residential	3	12,906 kr.	154,875 kr.	2,065 kr.	12/1/2021
250	1-3319-250-6	Fanny Jensens Plads 1, 3 mf, 2450 København SV	116	Residential	5	19,962 kr.	239,540 kr.	2,065 kr.	9/1/2020
251	1-3319-251-4	Fanny Jensens Plads 1, 2 mf, 2450 København SV	116	Residential	5	19,691 kr.	236,292 kr.	2,037 kr.	3/1/2022
252	1-3319-252-4	Fanny Jensens Plads 1, 3 th, 2450 København SV	89	Residential	3	15,382 kr.	184,586 kr.	2,074 kr.	7/1/2023
253	1-3319-253-8	Fanny Jensens Plads 1, 2 th, 2450 København SV	89	Residential	3	15,063 kr.	180,759 kr.	2,031 kr.	9/1/2021
254	1-3319-254-2	Fanny Jensens Plads 1, 4 tv, 2450 København SV	89	Residential	3	15,427 kr.	185,120 kr.	2,080 kr.	2/1/2020
255	1-3319-255-4	Fanny Jensens Plads 1, 3 tv, 2450 København SV	89	Residential	3	15,026 kr.	180,314 kr.	2,026 kr.	1/1/2022
256	1-3319-256-6	Fanny Jensens Plads 1, st th, 2450 København SV	101	Residential	4	17,279 kr.	207,353 kr.	2,053 kr.	4/1/2021
257	1-3319-257-6	Fanny Jensens Plads 3, st , 2450 København SV	67	Residential	2	11,273 kr.	135,273 kr.	2,019 kr.	8/1/2023
258	1-3319-258-2	Borgmester Christiansens Gade 47C, 2 th, 2450 København SV	60	Residential	2	10,185 kr.	122,220 kr.	2,037 kr.	6/1/2020
259	1-3319-259-2	Borgmester Christiansens Gade 47C, 3 th, 2450 København SV	60	Residential	2	10,185 kr.	122,220 kr.	2,037 kr.	3/1/2022
260	1-3319-260-2	Borgmester Christiansens Gade 47, 2450 København SV	148	Commercial	1	29,649 kr.	355,792 kr.	2,404 kr.	8/1/2020
261	1-3319-261-2	Borgmester Christiansens Gade 47B, st tv, 2450 København SV	111	Residential	5	19,185 kr.	230,214 kr.	2,074 kr.	5/1/2022
262	1-3319-262-4	Borgmester Christiansens Gade 47B, st th, 2450 København SV	72	Residential	2	12,420 kr.	149,040 kr.	2,070 kr.	2/1/2021
263	1-3319-263-8	Borgmester Christiansens Gade 47A, 3 2, 2450 København SV	66	Residential	2	11,286 kr.	135,432 kr.	2,052 kr.	5/1/2021
264	1-3319-264-2	Borgmester Christiansens Gade 47A, 1 2, 2450 København SV	66	Residential	2	11,451 kr.	137,412 kr.	2,082 kr.	12/1/2020
265	1-3319-265-2	Fanny Jensens Plads 1, st mf, 2450 København SV	89	Residential	3	15,278 kr.	183,340 kr.	2,060 kr.	2/1/2023
266	1-3319-266-2	Fanny Jensens Plads 7, 1 tv, 2450 København SV	70	Residential	3	11,888 kr.	142,660 kr.	2,038 kr.	4/1/2020
267	1-3319-267-2	Fanny Jensens Plads 5, 2 tv, 2450 København SV	69	Residential	3	11,615 kr.	139,380 kr.	2,020 kr.	8/1/2023
268	1-3319-268-8	Borgmester Christiansens Gade 47B, 1 mf, 2450 København SV	42	Residential	1	7,091 kr.	85,092 kr.	2,026 kr.	4/1/2022
269	1-3319-269-4	Gerda Louw Larsens Plads 4, 2 2, 2450 København SV	68	Residential	3	11,537 kr.	138,448 kr.	2,036 kr.	9/1/2020
270	1-3319-270-4	Gerda Louw Larsens Plads 4, st 2, 2450 København SV	52	Residential	2	8,901 kr.	106,808 kr.	2,054 kr.	11/1/2022
271	1-3319-271-8	Borgmester Christiansens Gade 47C, 1 th, 2450 København SV	60	Residential	2	10,120 kr.	121,440 kr.	2,024 kr.	8/1/2020
272	1-3319-272-8	Gerda Louw Larsens Plads 4, st 1, 2450 København SV	60	Residential	2	10,340 kr.	124,080 kr.	2,068 kr.	4/1/2023
273	1-3319-273-6	Gerda Louw Larsens Plads 2, st , 2450 København SV	78	Residential	3	13,449 kr.	161,382 kr.	2,069 kr.	8/1/2022
274	1-3319-274-2	Gerda Louw Larsens Plads 2, 1 tv, 2450 København SV	86	Residential	3	14,620 kr.	175,440 kr.	2,040 kr.	1/1/2022`;

export const units = unitData
  .split("\n")
  .map((row) => row.split("\t").map((cell) => cell.trim()))
  .map((row) => ({
    id: parseInt(row[0]),
    unikId: row[1],
    address: row[2],
    size: parseInt(row[3]),
    unitType: row[4],
    numRooms: parseInt(row[5]),
    monthlyRent: parseInt(row[6].replace(/,/g, "")),
    yearlyRent: parseInt(row[8].replace(/,/g, "")),
    sqrmRent: parseInt(row[8].replace(/,/g, "")),
    leaseStart: new Date(row[9]) == "Invalid Date" ? null : new Date(row[9]),
    warning: row[10] == "Vacant" ? "This unit is vacant" : null,
  }));

const tenantData = `1-3319-2-6	Anders Andersen	14,588 kr.	175,056 kr.	2,084 kr.	8/1/2023	6/1/2024	43,764 kr.	43,764 kr.		14,588 kr.
1-3319-2-5	Tomgang	10,415 kr.	124,980 kr.	2,083 kr.	3/1/2021	3/1/2022	31,245 kr.	31,245 kr.
1-3319-2-4	Jeanette Thomsen	10,215 kr.	122,580 kr.	2,043 kr.	3/1/2020	1/1/2021	30,645 kr.	30,645 kr.
1-3319-2-3	Tomgang	18,171 kr.	218,052 kr.	2,019 kr.	1/1/2020	6/1/2021	54,513 kr.	54,513 kr.
1-3319-2-2	Line Eckerdahl	10,001 kr.	120,006 kr.	2,034 kr.	10/1/2023	9/1/2024	30,002 kr.	30,002 kr.
1-3319-2-1	Tomgang	11,275 kr.	135,300 kr.	2,050 kr.	7/1/2020	2/1/2021	33,825 kr.	33,825 kr.		`;

export const tenants = tenantData
  .split("\n")
  .map((row) => row.split("\t").map((cell) => cell.trim()))
  .map((row, i) => ({
    id: i,
    unikId: row[0],
    name: row[1],
    monthlyRent: parseInt(row[2].replace(/,/g, "")),
    yearlyRent: parseInt(row[3].replace(/,/g, "")),
    sqrmRent: parseInt(row[4].replace(/,/g, "")),
    leaseStart: new Date(row[5]) == "Invalid Date" ? null : new Date(row[5]),
    leaseEnd: new Date(row[6]) == "Invalid Date" ? null : new Date(row[6]),
    deposit: parseInt(row[7].replace(/,/g, "")),
    prepaidRent: parseInt(row[8].replace(/,/g, "")),
    terminated: row[9] == "Terminated" ? true : false,
    arreas: row[10] != null ? parseInt(row[10].replace(/,/g, "")) : null,
  }));

export const occupancyRates = {
  xaxis: {
    categories: ["2010", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "2023"],
  },

  series: [
    {
      name: "Occupancy rate",
      data: [57, 59, 64, 67, 71, 75, 88, 92, 96, 98, 96, 93, 97, 98],
    },
  ],
};

export const churnRates = {
  xaxis: {
    categories: ["2010", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "2023"],
  },

  series: [
    {
      name: "Churn rate",
      data: [11, 8, 6, 7, 9, 4, 6, 7, 8, 3, 17, 19, 7, 2],
    },
  ],
};

export const rentDevelopment = {
  xaxis: {
    categories: ["2010", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "2023"],
  },

  series: [
    {
      name: "Rent development",
      data: [
        95772223, 103474742, 112309880, 114187444, 124097415, 132217594, 141845109, 154719805, 156711536, 170657843, 172249690, 188326431, 188918761, 193600935,
      ],
    },
  ],
};
